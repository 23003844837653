<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">培训答疑</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <!-- <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                clearable
                placeholder="请输入班级名称"
                size="small"
              ></el-input>
            </div> -->
             <div
              title="班级搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">班级搜索:</span>
              <el-select
                v-model="projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superProjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaProjectCode"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="seaProjectName"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in projectList"
                  :key="index"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                clearable
                placeholder="请输入课程名称"
                size="small"
              ></el-input>
            </div>
            <div title="创建时间" class="searchboxItem ci-full">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker
                v-model="createTime"
                size="small"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="提出内容" class="searchboxItem ci-full">
              <span class="itemLabel">提出内容:</span>
              <el-input
                v-model="content"
                clearable
                placeholder="请输入提出内容"
                size="small"
              ></el-input>
            </div>
            <div title="有无回复" class="searchboxItem ci-full">
              <span class="itemLabel">有无回复:</span>
              <el-select v-model="isReply" placeholder="请选择" size="small" clearable>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
              />
              <el-table-column
                label="手机号码"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                min-width="200"
              />
              <el-table-column
                label="提出内容"
                align="left"
                show-overflow-tooltip
                prop="content"
                min-width="200"
              />
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                min-width="200"
              />

              <el-table-column
                label="班级名称"
                align="left"
                prop="projectName"
                min-width="200"
              />
              <el-table-column
                label="创建日期"
                align="center"
                show-overflow-tooltip
                prop="createTime"
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="有无回复"
                align="center"
                show-overflow-tooltip
                prop="isReply"
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.isReply }}
                </template>
              </el-table-column>
              <el-table-column
                label="回复日期"
                align="center"
                show-overflow-tooltip
                prop="replyTime"
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.replyTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="160px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="seeInfo(scope.row)"
                    >回复</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      courseName: "", //课程名称
      projectName: "", //班级名称
      projectId: "", //班级名称
       seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      content: "", //回复内容
      isReply:'',
      createTime:'',
      options:[{
           value:'10',
          label:'是',
      },{
           value:'20',
          label:'否',
      }
      
      ]
    };
  },
  created() {
     this.superProjectSelect();
  },
  computed: {},
  mounted() {},
  methods: {
     superProjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
   
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    // 初始化获取评论列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.content) {
        params.content = this.content;
      }
      if (this.isReply) {
        params.isReply = this.isReply;
      }
      if (this.createTime) {
        params.createTimeFront = this.createTime[0] + ' ' + '00:00:00';
        params.createTimeAfter = this.createTime[1] + ' ' +'23:59:59';
      }
      this.doFetch({
        url: "/biz/comp/course/discuss/pageList",
        params,
        pageNum,
      });
    },
    // 点击查看
    seeInfo(row) {
      this.$router.push({
        path: "/web/trainingQusetionAnswerInfo",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    // 查看列表
    seeList() {
      this.$router.push({
        path: "/web/workOrder/commentManagementList",
      });
    },
  },
   watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      
      },
      // 深度观察监听
      deep: true,
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}</style>